import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './Components/LoginForm/LoginForm';
import Home from './Components/Home/Home';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("rsToken");
  return isAuthenticated ? children : <Navigate to="/" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm/>} />
        <Route path="/home/*" element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
