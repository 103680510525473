import axios from "axios";

//http://secura.multillantas.com
//https://test.multillantas.com
const apiBaseUrlSecura =
  "https://portal.reencauchadoraelsol.com/AppVentas/fwd_entryPoint.php"; //"/AppVentas/fwd_entryPoint.php";
const securaToken =
  "8850770ECA03004139B21CDEDC37AE9AFB4FC90553C35A615F9455C975BFAE2B";

const login = async (username, password) => {
  try {
    const response = await axios.post(
      `${apiBaseUrlSecura}?endpoint=api/index&action=clogin`,
      {
        username,
        password,
      },
      {
        headers: { "X-Secura-Token": securaToken },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export default login;
