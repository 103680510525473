import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "./../Home/Header";
import { useOSContext } from "../../OSContext";
import { useState, useEffect } from "react";
import osData from "./../Api/OSData";
import { useNavigate } from "react-router-dom";

const DBTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State to store the data
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const { OS, Id, Usuario, EsCel } = useOSContext();

  const navigate = useNavigate();
  const isMobile = EsCel; // Assuming EsCel indicates if it's mobile

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date as MM/DD/YYYY
    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);

    // Format time as HH:mm
    const timeFormatted = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);

    return `${dateFormatted} ${timeFormatted}`;
  };

  const getStatusColor = (value) => {
    const firstWord = value.split(" ")[0]; // Get the first word of the 'estado' value
    switch (firstWord) {
      case "Proceso":
        return "green";
      case "Anulado":
        return "red";
      default:
        return "gray";
    }
  };

  const StatusCell = (params) => {
    const color = getStatusColor(params.value);
    return (
      <Box
        sx={{
          height: 20,
          width: 20,
          borderRadius: "50%",
          backgroundColor: color,
          display: "inline-block",
        }}
      ></Box>
    );
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("rsToken");
        const response = await osData(token, Id, OS);

        if (response && response.message === "Token expired") {
          // Redirect to login page if token has expired
          navigate("/"); // Adjust the path as necessary
          return;
        }

        // Handle successful login
        if (response && response.Customer && Array.isArray(response.Customer)) {
          // Map the fetched data into the format required for DataGrid
          const rowData = response.Customer.map((item, index) => ({
            id: item.id_cartilla || index, // Use index as fallback ID if necessary
            descripcion: item.Descripcion,
            numeroSerie: item.NumeroSerie,
            marca: item.Marca,
            estado: item.Estado,
            fecha:
              item.Fecha_Produccion && item.Fecha_Produccion.date
                ? new Date(item.Fecha_Produccion.date).toLocaleDateString()
                : "N/A", // Default value if date is null or undefined
            fechaActual:
              item.Fecha_Ultima_Etapa && item.Fecha_Ultima_Etapa.date
                ? formatDate(item.Fecha_Ultima_Etapa.date) //new Date(item.Fecha_Ultima_Etapa.date).toLocaleString()
                : "N/A", // Default value if date is null or undefined
          }));

          // Update state with the transformed data
          setData(rowData);
        } else {
          setError("Invalid response structure");
        }
      } catch (error) {
        setError("Failed to fetch data. Please check your credentials.");
        console.error("Error fetching data:", error);
        navigate("/"); // Redirect to login on token expiration
      }
    };

    // Fetch data only if OS and Username are available
    if (OS && Id) {
      fetchData();
    }
  }, [OS, Id, navigate]);

  const getColumns = () => {
    if (isMobile) {
      return [
        {
          field: "descripcion",
          headerName: "Descripción",
          flex: 1.8,
        },
        {
          field: "marca",
          headerName: "Marca",
          flex: 0.5,
        },
        { field: "fechaActual", headerName: "Fecha", flex: 0.6 },
        {
          field: "estado",
          headerName: "       ",
          flex: 0.08,
          renderCell: StatusCell,
        },
      ];
    } else {
      return [
        { field: "id", headerName: "ID", flex: 0.5 },
        {
          field: "descripcion",
          headerName: "Descripción",
          flex: 2,
        },
        {
          field: "numeroSerie",
          headerName: "Número de Serie",
          flex: 0.8,
        },
        {
          field: "marca",
          headerName: "Marca",
          flex: 0.5,
        },
        {
          field: "fecha",
          headerName: "Fecha Producción",
          flex: 0.7,
        },
        { field: "fechaActual", headerName: "Fecha Última Etapa", flex: 0.7 },
        {
          field: "estadoColor",
          headerName: "",
          flex: 0.1,
          renderCell: (params) => <StatusCell value={params.row.estado} />, // Use StatusCell to render the colored circle
          sortable: false, // Optional: Disable sorting for the visual column
        },
        {
          field: "estado",
          headerName: "Estado",
          flex: 2,
        },
      ];
    }
  };

  return (
    <Box m="20px">
      <Header
        title="DETALLE ORDEN DE SERVICIO"
        subtitle={`Correspondiente a la OS N° ${OS} de ${Usuario}`}
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bluAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bluAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          columns={getColumns()}
          //getRowHeight={() => "auto"}
          rows={data}
          pageSize={12}
          hideFooter
          disableColumnMenu={EsCel}
          //hideFooterPagination
        />
      </Box>
    </Box>
  );
};

export default DBTable;
