import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";
import React from "react";
import "./Home.css";
import { useLocation, Navigate } from "react-router-dom";
import DBTable from "./../DataTable/DBTable";
import DBEvo from "./../DataTable/DBEvo";
import { OSContextProvider } from "../../OSContext";

function Home() {
  const location = useLocation();
  const { id, NombreUsuario, OS } = location.state || {};
  const [isCollapsed, setIscollapsed] = useState(isMobile);
  const [theme, colorMode] = useMode();

  useEffect(() => {
    // Add class to body on mount
    document.body.classList.add("home-body");
    // Remove class from body on unmount
    return () => {
      document.body.classList.remove("home-body");
    };
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <OSContextProvider
        initialId={id}
        initialOS={OS}
        initialUser={NombreUsuario}
        initialIsMobile={isMobile}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar
              isCollapsed={isCollapsed}
              setIsCollapsed={setIscollapsed}
            />
            <main
              className={`content ${isCollapsed ? "collapsed" : "expanded"}`}
            >
              <Topbar />
              <Routes>
                <Route path="/" element={<DBTable />} />
                <Route path="/Faq" element={<DBEvo />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </OSContextProvider>
    </ColorModeContext.Provider>
  );
}

export default Home;
