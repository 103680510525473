import { Box, useTheme } from "@mui/material";
import Header from "./../Home/Header";
import React from "react";
import { tokens } from "../../theme";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function DBEvo() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function createData(descripcion, banco, recaudo, cuenta, cci, moneda) {
    return { descripcion, moneda, banco, recaudo, cuenta, cci };
  }

  const rows = [
    createData(
      "BBVA REENCAUCHADORA EL SOL SAC DOLARES",
      "BBVA",
      3812,
      "0011-0335-01-00005680",
      "011-335-000100005680-72",
      "DÓLARES ($)"
    ),
    createData(
      "BBVA REENCAUCHADORA EL SOL SAC SOLES",
      "BBVA",
      3811,
      "0011-0335-01-00003130",
      "011-335-000100003130-70",
      "SOLES (S/)"
    ),
    createData(
      "BCP REENCAUCHADORA EL SOL SAC DOLARES",
      "BCP",
      2999,
      "191-0860649-1-32",
      "002-191-000860649132-55",
      "DÓLARES ($)"
    ),
    createData(
      "BCP REENCAUCHADORA EL SOL SAC SOLES",
      "BCP",
      2999,
      "191-0076038-0-59",
      "002-191-000076038059-52",
      "SOLES (S/)"
    ),
    createData(
      "SCOTIABANK REENCAUCHADORA EL SOL SAC DOLARES",
      "SCOTIABANK",
      "",
      "000-3030831",
      "009-170-000003030831-26",
      "DÓLARES ($)"
    ),
    createData(
      "SCOTIABANK REENCAUCHADORA EL SOL SAC SOLES",
      "SCOTIABANK",
      "",
      "000-5099579",
      "009-170-000005099579-24",
      "SOLES (S/)"
    ),
  ];

  return (
    <Box m="20px">
      <Header title="Preguntas Frecuentes" subtitle="Sobre Nosotros" />
      <Box m="5px">
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colors.greenAccent[500]} variant="h5">
              Nuestros Números de Cuenta
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Descripción de la Cuenta</TableCell>
                    <TableCell align="center">Banco</TableCell>
                    <TableCell align="center">Moneda</TableCell>
                    <TableCell align="center">N° Recaudo</TableCell>
                    <TableCell align="left">Número de Cuenta</TableCell>
                    <TableCell align="left">Número Interbancario</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.descripcion}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.descripcion}
                      </TableCell>
                      <TableCell align="center">{row.banco}</TableCell>
                      <TableCell align="center">{row.moneda}</TableCell>
                      <TableCell align="center">{row.recaudo}</TableCell>
                      <TableCell align="left">{row.cuenta}</TableCell>
                      <TableCell align="left">{row.cci}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colors.greenAccent[500]} variant="h5">
              Reencauchadora el Sol - ISO 9001
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="inline-flex">
              <Box maxWidth="980px" margin="10px">
                <Typography>
                  Nuestra vasta experiencia, sumada a nuestro personal altamente
                  calificado y nuestra moderna planta de reencauche, nos
                  permiten realizar un servicio de calidad en cada uno de los
                  reencauches que realizamos.
                </Typography>
                <Typography>
                  Nuestra incansable búsqueda de mejora continua nos impulsa a
                  ser ser cada vez más eficientes en nuestro trabajo, es por eso
                  que contamos con el Certificado ISO 9001 en: Servicio de
                  Reparación y Reencauche de Neumáticos OTR, Industrial, Camión,
                  Bus y Camioneta.
                </Typography>
              </Box>
              <Box backgroundColor="white" height="57px" margin="10px">
                <img
                  className="bordered-img"
                  src="https://reencauchadoraelsol.com/wp-content/uploads/2023/06/cropped-logo-iso-80-anos-300x57.png"
                  width={300}
                  height={57}
                  alt="Reencauchadora El Sol ISO 9001"
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default DBEvo;
