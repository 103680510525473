import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./LoginForm.css";
import { FaUser } from "react-icons/fa";
import { IoDocumentLockOutline } from "react-icons/io5";
import login from "./../Api/Login";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [attempts, setAttempts] = useState(0); // Track failed attempts
  const [lockout, setLockout] = useState(false); // Lockout state
  const [countdown, setCountdown] = useState(0); // Countdown for lockout
  const navigate = useNavigate();
  const maxAttempts = 3; // Maximum allowed attempts
  const lockoutDuration = 300; // Lockout duration in seconds

  // Load remembered username and lockout state on component mount
  useEffect(() => {
    const rememberedUsername = localStorage.getItem("rememberedUsername");
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }

    // Restore lockout state if present
    const savedLockout = localStorage.getItem("lockoutState");
    const savedCountdown = localStorage.getItem("lockoutCountdown");

    if (savedLockout === "true") {
      const remainingTime =
        parseInt(savedCountdown, 10) - Math.floor(Date.now() / 1000);
      if (remainingTime > 0) {
        setLockout(true);
        setCountdown(remainingTime);
        setError(
          `Se ha excedio el límite de intentos. Por favor intente de nuevo en ${remainingTime} segundos.`
        );
      } else {
        // Clear lockout if time has expired
        localStorage.removeItem("lockoutState");
        localStorage.removeItem("lockoutCountdown");
      }
    }
  }, []);

  useEffect(() => {
    if (lockout && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            setLockout(false); // Unlock after countdown ends
            setAttempts(0); // Reset attempts
            setError(""); // Clear error message
            localStorage.removeItem("lockoutState");
            localStorage.removeItem("lockoutCountdown");
            clearInterval(timer);
            return 0;
          }
          return prev - 1; // Decrease countdown
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [lockout, countdown]);

  useEffect(() => {
    if (lockout && countdown > 0) {
      setError(
        `Se ha excedio el límite de intentos. Por favor intente de nuevo en ${countdown} segundos.`
      );
    }
  }, [countdown, lockout]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (lockout) {
      setError(
        `Se ha excedio el límite de intentos. Por favor intente de nuevo en ${countdown} segundos.`
      );
      return;
    }

    try {
      const response = await login(username, password);
      if (response && response.Customer) {
        const { id, NombreUsuario, token, OS } = response.Customer;
        localStorage.setItem("rsToken", token);

        if (rememberMe) {
          localStorage.setItem("rememberedUsername", username);
        } else {
          localStorage.removeItem("rememberedUsername");
        }
        navigate("/Home", { state: { id, NombreUsuario, OS } });
      } else {
        const newAttempts = attempts + 1;
        setAttempts(newAttempts);
        if (newAttempts >= maxAttempts) {
          setLockout(true); // Lock the user out after too many attempts
          setCountdown(lockoutDuration); // Set countdown only once
          setError(
            `Se ha excedio el límite de intentos. Por favor intente de nuevo en ${lockoutDuration} segundos.`
          );

          const lockoutExpiry = Math.floor(Date.now() / 1000) + lockoutDuration;
          localStorage.setItem("lockoutState", "true");
          localStorage.setItem("lockoutCountdown", lockoutExpiry);
        } else {
          setError(
            "Falló el inicio de sesión. Por favor, revise sus credenciales."
          );
        }
      }
    } catch (error) {
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      if (newAttempts >= maxAttempts) {
        setLockout(true); // Lock the user out after too many attempts
        setCountdown(lockoutDuration); // Set countdown only once
        setError(
          `Se ha excedio el límite de intentos. Por favor intente de nuevo en ${lockoutDuration} segundos.`
        );

        const lockoutExpiry = Math.floor(Date.now() / 1000) + lockoutDuration;
        localStorage.setItem("lockoutState", "true");
        localStorage.setItem("lockoutCountdown", lockoutExpiry);
      } else {
        setError(
          "Falló el inicio de sesión. Por favor, revise sus credenciales."
        );
      }
    }
  };

  return (
    <div className="login-background">
      <div className="wrapper">
        <form action="" onSubmit={handleSubmit}>
          <h1>Inicio de sesión</h1>
          <div className="input-box">
            <input
              type="text"
              placeholder="RUC"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <FaUser className="icon" />
          </div>
          <div className="input-box">
            <input
              type="text"
              placeholder="Orden de Servicio"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <IoDocumentLockOutline className="icon" />
          </div>
          {error && <p className="error-message">{error}</p>}{" "}
          <div className="remember-forgot">
            <label>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              Recuérdame
            </label>
          </div>
          <button type="submit" disabled={lockout}>
            Iniciar Sesión
          </button>{" "}
          <div className="register-link">
            <p>
              No eres cliente aún?{" "}
              <a
                href="https://wa.me/51956558211?text=Estoy%20interesado%20en%20ser%20su%20cliente&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Comunícate con nosotros
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
