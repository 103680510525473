import { Box, IconButton, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { LightModeOutlined as LightModeOutlinedIcon } from "@mui/icons-material";
import { DarkModeOutlined as DarkModeOutlinedIcon } from "@mui/icons-material";
import "../DataTable/Watermark.css";
//import { PersonOutlined as PersonOutlinedIcon } from "@mui/icons-material";
//import { SettingsOutlined as SettingsOutlinedIcon } from "@mui/icons-material";

function Topbar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <div className="watermark-container">
      <Box display="flex" justifyContent="end" p={2}>
        {/*ICONS*/}

        <Box display="flex" background={colors.primary[400]} borderRadius="3px">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          {/*
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>
        */}
        </Box>
      </Box>
    </div>
  );
}

export default Topbar;
