import React from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useState } from "react";

const OSContext = createContext();

export const OSContextProvider = ({
  children,
  initialId,
  initialOS,
  initialUser,
  initialIsMobile,
}) => {
  const [Id, setId] = useState(initialId || null);
  const [OS, setOS] = useState(initialOS || "");
  const [Usuario, setUsuario] = useState(initialUser || "");
  const [EsCel, setEsCel] = useState(initialIsMobile || false);
  return (
    <OSContext.Provider
      value={{
        Id,
        setId,
        Usuario,
        setUsuario,
        OS,
        setOS,
        EsCel,
        setEsCel,
      }}
    >
      {children}
    </OSContext.Provider>
  );
};

export const useOSContext = () => useContext(OSContext);
